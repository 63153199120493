import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';

@Component({
  selector: 'app-property-for-sale',
  templateUrl: './property-for-sale.component.html',
  styleUrls: ['./property-for-sale.component.scss']
})
export class PropertyForSaleComponent implements OnInit {

  pageNumber: Number = 1;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  changePage($event) {
    this.pageNumber = $event;
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }
}